<template>
  <div>
    <div class="pa-32">
      <v-row no-gutters>
        <v-col v-for="currency in currencies" :key="currency.code" cols="12" sm="6" md="6" lg="3">
          <div class="d-flex justify-center align-center mb-5">
            <v-img
              style="height: 32px;"
              content-class="rounded-lg"
              class="rounded-lg mr-2"
              aspect-ratio="16/9"
              max-width="32"
              width="32"
              :src="require(`./../assets/${currency.flag}.png`)"
            />
            <span>{{ currency.code }}</span>
          </div>
          <div v-for="(slide, i) in filterData(currency.code)" :key="`${currency.code}-${i}`" @click="openNews(slide.url)" class="pr-4 pb-4 fade" style="cursor: pointer;">
            <div class="d-flex">
              <span class="text-body-2 text--secondary text-no-wrap">
                {{ slide.time_published | formatCustomDate }}
              </span>
              <v-icon>mdi-circle-small</v-icon>
              <span class="text-body-2 text--secondary d-inline-block text-truncate" style="max-width: 300px;">
                {{ slide.source }}
              </span>
            </div>
            <div class="text-body-1 text--primary" style="max-width: 500px;">
              {{ slide.title.length <= 70 ? slide.title : slide.title.slice(0,70).trim().concat("...") }} 
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { i18n } from "./../plugins/vuetify"

export default {
  props: {
    slides: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      currencies: [
        { code: 'BRL', flag: 'brazil_flag' },
        { code: 'USD', flag: 'usd_flag' },
        { code: 'EUR', flag: 'eu_flag' },
        { code: 'JPY', flag: 'japan_flag' },
      ]
    }
  },
  filters: {
    formatCustomDate(dateString) {
      let day, month, year, hours, minutes
      
      year = dateString.slice(0, 4)
      month = dateString.slice(4, 6)
      day = dateString.slice(6, 8)
      hours = dateString.slice(9, 11)
      minutes = dateString.slice(11, 13)

      const locale = i18n.locale

      const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}`)
      const dateOptions = { day: 'numeric', month: 'long' }
      const formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(date)
      const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true }
      const formattedTime = new Intl.DateTimeFormat(locale, timeOptions).format(date)
      
      return `${formattedDate}, ${formattedTime}`
    }
  },
  computed: {
    limitedSlides() {
      return this.slides.slice(0, 15)
    },
  },
  methods: {
    openNews(url) {
      window.open(url, "_blank")
    },
    filterData(forex) {
      if (!this.slides) {
        return
      }

      const flattenedSlides = this.slides.flatMap(slideGroup => 
        Object.values(slideGroup.feed).filter(slide => {
          const titleOrContent = (slide.title).toLowerCase() + (slide.summary).toLowerCase()
          return !titleOrContent.includes('bitcoin') && !titleOrContent.includes('crypto')
        })
      )

      let forexData = flattenedSlides.filter(slide => {
        if (slide.ticker_sentiment) {
          return slide.ticker_sentiment.some(tickerData => tickerData.ticker === `FOREX:${forex}`)
        }
        return false
      })
      forexData = forexData.slice(0,4)
      forexData.sort((a, b) => {
        const dateA = new Date(
          `${a.time_published.slice(0, 4)}-${a.time_published.slice(4, 6)}-${a.time_published.slice(6, 8)}T${a.time_published.slice(9, 11)}:${a.time_published.slice(11, 13)}`
        )
        const dateB = new Date(
          `${b.time_published.slice(0, 4)}-${b.time_published.slice(4, 6)}-${b.time_published.slice(6, 8)}T${b.time_published.slice(9, 11)}:${b.time_published.slice(11, 13)}`
        )
        return dateB - dateA
      })
      return forexData
    }
  }
}
</script>

<style>
.fade:hover {
  background-color: rgb(45, 45, 45);
  border-radius: 1%;
}
</style>
