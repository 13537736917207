<template>
  <div>
    <div class="cross-quotation-table">
      <v-simple-table class="table">
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th class="text-center font-size">
                <div class="d-flex justify-center align-center">
                  <v-img
                  style="height: 32px;"
                  content-class="rounded-lg"
                  class="rounded-lg mr-2"
                  aspect-ratio="16/9"
                  max-width="32"
                  width="32"
                  :src="require(`./../assets/usd_flag.png`)"
                  />
                  <span>USD</span>
                </div>
              </th>
              <th class="text-center font-size">
                <div class="d-flex justify-center align-center">
                  <v-img
                    style="height: 32px;"
                    content-class="rounded-lg"
                    class="rounded-lg mr-2"
                    aspect-ratio="16/9"
                    max-width="32"
                    width="32"
                    :src="require(`./../assets/eu_flag.png`)"
                  />
                  <span>EUR</span>
                </div>
              </th>
              <th class="text-center font-size">
                <div class="d-flex justify-center align-center">
                  <v-img
                    style="height: 32px;"
                    content-class="rounded-lg"
                    class="rounded-lg mr-2"
                    aspect-ratio="16/9"
                    max-width="32"
                    width="32"
                    :src="require(`./../assets/japan_flag.png`)"
                  />
                  <span>JPY</span>
                </div>
              </th>
              <th class="text-center font-size">
                <div class="d-flex justify-center align-center">
                  <v-img
                    style="height: 32px;"
                    content-class="rounded-lg"
                    class="rounded-lg mr-2"
                    aspect-ratio="16/9"
                    max-width="32"
                    width="32"
                    :src="require(`./../assets/brazil_flag.png`)"
                  />
                  <span>BRL</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="crossQuotesMapped">
              <tr v-for="(quote, index) in crossQuotesMapped" :key="quote.fromCcy" class="text-center">
                <td class="font-size">
                  <div class="d-flex justify-center align-center">
                    <v-img
                      v-if="quote.fromCcy === 'USD'"
                      style="height: 32px;"
                      content-class="rounded-lg"
                      class="rounded-lg mr-2"
                      aspect-ratio="16/9"
                      max-width="32"
                      width="32"
                      :src="require(`./../assets/usd_flag.png`)"
                    />
                    <v-img
                      v-else-if="quote.fromCcy === 'EUR'"
                      style="height: 32px;"
                      content-class="rounded-lg"
                      class="rounded-lg mr-2"
                      aspect-ratio="16/9"
                      max-width="32"
                      width="32"
                      :src="require(`./../assets/eu_flag.png`)"
                    />
                    <v-img
                      v-else-if="quote.fromCcy === 'JPY'"
                      style="height: 32px;"
                      content-class="rounded-lg"
                      class="rounded-lg mr-2"
                      aspect-ratio="16/9"
                      max-width="32"
                      width="32"
                      :src="require(`./../assets/japan_flag.png`)"
                    />
                    <v-img
                      v-else-if="quote.fromCcy === 'BRL'"
                      style="height: 32px;"
                      content-class="rounded-lg"
                      class="rounded-lg mr-2"
                      aspect-ratio="16/9"
                      max-width="32"
                      width="32"
                      :src="require(`./../assets/brazil_flag.png`)"
                    />
                    <span>{{ quote.fromCcy }}</span>
                  </div>
                </td>
                <td
                  :class="{
                    'empty': !quote.values['USD'] || quote.values['USD'] === crossQuotesData[index].values['USD'],
                    'orangeColor--text': quote.values['USD'] < crossQuotesData[index].values['USD'],
                    'alive--text': quote.values['USD'] > crossQuotesData[index].values['USD']
                  }"
                  :style="{
                    backgroundColor: 
                      quote.values['USD'] < crossQuotesData[index].values['USD'] ? 
                      yellowTransp : 
                        (quote.values['USD'] > crossQuotesData[index].values['USD'] ? 
                          aliveTransp : '')
                  }"
                  class="text-center align-center font-size"
                >
                  {{ quote.values["USD"] }}
                </td>
                <td
                  :class="{
                    'empty': !quote.values['EUR'] || quote.values['EUR'] === crossQuotesData[index].values['EUR'],
                    'orangeColor--text': quote.values['EUR'] < crossQuotesData[index].values['EUR'],
                    'alive--text': quote.values['EUR'] > crossQuotesData[index].values['EUR']
                  }"
                  :style="{
                    backgroundColor: 
                      quote.values['EUR'] < crossQuotesData[index].values['EUR'] ? 
                      yellowTransp : 
                        (quote.values['EUR'] > crossQuotesData[index].values['EUR'] ? 
                          aliveTransp : '')
                  }"
                  class="text-center align-center font-size"
                >
                  {{ quote.values["EUR"] }}
                </td>
                <td
                  :class="{
                    'empty': !quote.values['JPY'] || quote.values['JPY'] === crossQuotesData[index].values['JPY'],
                    'orangeColor--text': quote.values['JPY'] < crossQuotesData[index].values['JPY'],
                    'alive--text': quote.values['JPY'] > crossQuotesData[index].values['JPY']
                  }"
                  :style="{
                    backgroundColor: 
                      quote.values['JPY'] < crossQuotesData[index].values['JPY'] ? 
                      yellowTransp : 
                        (quote.values['JPY'] > crossQuotesData[index].values['JPY'] ? 
                          aliveTransp : '')
                  }"
                  class="text-center align-center font-size"
                >
                  {{ quote.values["JPY"] }}
                </td>
                <td
                  :class="{
                    'empty': !quote.values['BRL'] || quote.values['BRL'] === crossQuotesData[index].values['BRL'],
                    'orangeColor--text': quote.values['BRL'] < crossQuotesData[index].values['BRL'],
                    'alive--text': quote.values['BRL'] > crossQuotesData[index].values['BRL']
                  }"
                  :style="{
                    backgroundColor: 
                      quote.values['BRL'] < crossQuotesData[index].values['BRL'] ? 
                      yellowTransp : 
                        (quote.values['BRL'] > crossQuotesData[index].values['BRL'] ? 
                          aliveTransp : '')
                  }"
                  class="text-center align-center font-size"
                >
                  {{ quote.values["BRL"] }}
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useAlgoRFQStore, useUtilsStore } from "./../store/index";

export default {
  data() {
    return {
      crossQuotes: [],
      crossQuotesData: [],
      // data: [
      //   {
      //     "from_currency": "USD",
      //     "to_currency": "EUR",
      //     "exchange_rate": "0.89530000",
      //     "last_refreshed": "2024-08-27 14:14:01",
      //     "time_zone": "UTC"
      //   },
      //   {
      //     "from_currency": "EUR",
      //     "to_currency": "JPY",
      //     "exchange_rate": "161.290322",
      //     "last_refreshed": "2024-08-27 14:14:07",
      //     "time_zone": "UTC"
      //   },
      //   {
      //     "from_currency": "JPY",
      //     "to_currency": "USD",
      //     "exchange_rate": "0.00690000",
      //     "last_refreshed": "2024-08-27 14:14:02",
      //     "time_zone": "UTC"
      //   },
      //   {
      //     "from_currency": "JPY",
      //     "to_currency": "BRL",
      //     "exchange_rate": "0.03800000",
      //     "last_refreshed": "2024-08-27 14:14:14",
      //     "time_zone": "UTC"
      //   },
      //   {
      //     "from_currency": "BRL",
      //     "to_currency": "USD",
      //     "exchange_rate": "0.18200000",
      //     "last_refreshed": "2024-08-27 14:14:02",
      //     "time_zone": "UTC"
      //   },
      //   {
      //     "from_currency": "BRL",
      //     "to_currency": "EUR",
      //     "exchange_rate": "0.16270000",
      //     "last_refreshed": "2024-08-27 14:14:15",
      //     "time_zone": "UTC"
      //   },
      // ],
      interval: null
    }
  },
  mounted() {
    this.fetchCrossQuotes()
    this.interval = setInterval(this.fetchCrossQuotes, 15000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions(useAlgoRFQStore, [
      "getFxMarketCrossQuote"
    ]),
    fetchCrossQuotes() {
      const currencyPairs = [
        { fromCcy: "USD", toCcy: "EUR" },
        { fromCcy: "EUR", toCcy: "JPY" },
        { fromCcy: "JPY", toCcy: "USD" },
        { fromCcy: "JPY", toCcy: "BRL" },
        { fromCcy: "BRL", toCcy: "USD" },
        { fromCcy: "BRL", toCcy: "EUR" }
      ]

      this.getFxMarketCrossQuote(currencyPairs)
        .then((response) => {
          const data = response.data
          this.crossQuotes = data
          this.saveCrossQuotesData()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveCrossQuotesData () {
      this.crossQuotesData = this.crossQuotesData.concat(this.crossQuotesMapped)
    }
  },
  computed: {
    ...mapState(useUtilsStore, [
      "yellowTransp",
      "aliveTransp"
    ]),
    crossQuotesMapped () {
      const date = new Date()
      const allQuotes = this.crossQuotes.concat(
        this.crossQuotes.map((pair) => ({
          from_currency: pair.to_currency,
          to_currency: pair.from_currency,
          exchange_rate: 1 / pair.exchange_rate,
          last_refreshed: pair.last_refreshed,
          time_zone: date,
        }))
      )

      return allQuotes.reduce((result, cotation) => {
        const { from_currency, to_currency, exchange_rate } = cotation

        const fromCcy = result.find(m => m.fromCcy === from_currency) || {
          fromCcy: from_currency,
          values: {}
        }

        fromCcy.values[to_currency] = (1 / parseFloat(exchange_rate)).toFixed(4)
                
        if (!result.find(m => m.fromCcy === from_currency)) {
            result.push(fromCcy)
        }
        return result
      }, [])
    }
  }
}
</script>

<style scoped>
  .font-size {
    font-size: 16px !important;
  }
  .table {
    background-color: transparent;
  }
  
  table th {
    border: 1px solid #ffffff1f;
  }

  table td {
    border: 1px solid #ffffff1f;
  }

  .empty {
    background-color: rgb(30, 29, 29)
  }
</style>