import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from './assets/clearfx.ai.svg';
import * as utils from "./utils.js";
import i18n from './i18n/i18n.js'; 

export function convertSvgToBase64(svgUrl) {
  return fetch(svgUrl)
    .then(response => response.text())
    .then(svgText => {
      const svgBlob = new Blob([svgText], { type: 'image/svg+xml' });
      const svgUrlBlob = URL.createObjectURL(svgBlob);
      const img = new Image();

      return new Promise((resolve, reject) => {
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(svgUrlBlob);
          resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = reject;
        img.src = svgUrlBlob;
      });
    });
}

export function exportPDF(reportsData) {
  const doc = new jsPDF();

  convertSvgToBase64(logo).then(imgData => {
    const imgHeight = 15;
    const pageHeight = doc.internal.pageSize.height;
    const marginTop = 10;
    const marginBottom = 5;
    const tableHeight = 21;
    let currentY = imgHeight + 34;

    const addHeader = () => {
      doc.setFillColor(41, 129, 186);
      doc.rect(13, 10, 183, 25, 'F');
      doc.addImage(imgData, 'PNG', 15, 15, 65, imgHeight);

      doc.setFont("helvetica", 'bold');
      doc.setFontSize(22);
      doc.text(i18n.t('pdf.title'), 13.5, 43);
      doc.setFont('helvetica', 'normal');
      doc.text('__________________________________________', 14.3, 44);
      doc.setFontSize(14);

      doc.setTextColor(255, 255, 255);
      const now = new Date();
      const locale = localStorage.getItem('userLanguage') || 'en'; // Pegar o idioma salvo no localStorage

      const dateOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }

      const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: 'numeric',
        hour12: true,
      }

      const formattedDate = now.toLocaleDateString(locale, dateOptions)
      const formattedTime = now.toLocaleTimeString(locale, timeOptions)

      // const formattedNow = now.toLocaleString(locale, {
      //   day: 'numeric',
      //   month: 'numeric',
      //   year: 'numeric',
      //   hour: 'numeric',
      //   minute: 'numeric',
      //   second: 'numeric'
      // });

      const formattedNow = formattedDate + ', ' + formattedTime;
      doc.text(formattedNow, 140, 24);
      doc.setTextColor(7, 7, 7);
    };

    addHeader();

    reportsData.forEach((item) => {
      const remainingSpace = pageHeight - currentY - marginBottom;
      if (remainingSpace < tableHeight) {
        doc.addPage();
        currentY = imgHeight + 34;
        addHeader();
      }

      const reportHeaders = [
        i18n.t('quoteInfo.dealTime'),
        i18n.t('quoteInfo.ccy'),
        i18n.t('quoteInfo.amountCcy'),
        i18n.t('quoteInfo.settlement'),
        i18n.t('quoteInfo.id'),
      ];
      const reportData = [
        [
          utils.getLocaleFormattedDateTime(item.rfq_deal_time),
          item.rfq_ccy,
          utils.formatPrice(item.rfq_amount),
          item.rfq_spotsett,
          item.rfq_id
        ]
      ];

      doc.autoTable({
        startY: currentY,
        head: [reportHeaders],
        body: reportData,
        headStyles: { fillColor: [7, 7, 7] },
        didDrawPage: (data) => {
          currentY = data.cursor.y;
        }
      });

      const quotes = item.rfq_lastquote.quote_list;
      if (quotes && quotes.length > 0) {
        const remainingSpaceForQuotes = pageHeight - currentY - marginBottom;
        if (remainingSpaceForQuotes < tableHeight) {
          doc.addPage();
          currentY = marginTop;
          addHeader();
        }

        doc.autoTable({
          startY: currentY,
          head: [
            [
              i18n.t('quoteInfo.status'),
              i18n.t('quoteInfo.bankName'),
              i18n.t('quoteInfo.bankRate'),
              i18n.t('quoteInfo.spreadAvg'),
              i18n.t('quoteInfo.spreadPtax'),
              i18n.t('quoteInfo.bankLastUpdate'),
            ]
          ],
          body: quotes.map(rfq => [
            rfq.deal ? i18n.t('reports.expand.rates.accepted').toUpperCase() : i18n.t('reports.expand.rates.declined').toUpperCase(),
            rfq.bank_label,
            rfq.quote_bank_rate,
            rfq.spread_avg_spot,
            rfq.spread_ptax,
            utils.getLocaleFormattedDateTime(rfq.quote_bank_time)
          ]),
          headStyles: { fillColor: [165, 165, 165] },
          didDrawPage: (data) => {
            currentY = data.cursor.y;
          }
        });
      }

      currentY = doc.previousAutoTable.finalY + marginBottom;
    });

    doc.save('report_fx.pdf');
  }).catch(error => {
    console.error("Error converting SVG to base64:", error);
  });
}
