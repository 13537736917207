<template>
  <div>
    <div v-for="(groupedEvents, date) in groupedEventsByDate" :key="date">
      <div class="d-flex table-title pl-4 py-1">
        <div class="header-column">
          <span>{{ formatCustomDate(date) }}</span>
        </div>
      </div>
      <v-data-table 
        :headers="headers" 
        :items="groupedEvents" 
        item-key="id" 
        hide-default-header
        hide-default-footer 
        class="transparent"
        :expanded="expanded"
        :items-per-page="100"
      >
        <template v-slot:[`item.expand`]="{ item }">
          <v-icon @click="toggleExpand(item)" v-if="item.comment">
            {{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </template>
        <template #[`item.date`]="{ item }">
          <td class="time-column">
            <span>{{ formatTime(item.date) }}</span>
          </td>
        </template>
        <template #[`item.country`]="{ item }">
          <td class="icon-column">
            <span>{{ item.country }}</span>
          </td>
        </template>
        <template #[`item.importance`]="{ item }">
          <td class="icon-column">
            <v-icon>{{ getSignalIcon(item.importance) }}</v-icon>
          </td>
        </template>
        <template #[`item.title`]="{ item }">
          <td class="headline-column">
            <span 
              v-if="item.source_url" 
              @click="openEvent(item.source_url)" 
              style="cursor: pointer;"
            >
              {{ item.title }}
            </span>
            <span v-else>{{ item.title }}</span>
          </td>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-card flat class="transparent">
              <v-card-text>{{ item.comment }}</v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: []
    }
  },
  props: {
    events: {
      required: true,
      type: Array
    },
  },
  computed: {
    headers() {
      return [
        { text: '', value: 'expand', align: 'start' },
        { text: this.$t('economicCalendar.date'), value: 'date' },
        { text: this.$t('economicCalendar.country'), value: 'country' },
        { text: this.$t('economicCalendar.importance'), value: 'importance' },
        { text: this.$t('economicCalendar.title'), value: 'title' },
      ]
    },
    convertedEvents() {
      return this.events.map(
        (event) => {
          let result = {}
          result["actual"] = event.actual
          result["actualRaw"] = event.actualRaw
          result["category"] = event.category
          result["comment"] = event.comment
          result["country"] = event.country
          result["currency"] = event.currency
          const dateString = event.date
          const date = new Date(dateString)
          const convertedEvent = date.getTime()
          result["date"] = convertedEvent
          result["forecast"] = event.forecast
          result["forecastRaw"] = event.forecastRaw
          result["id"] = event.id
          result["importance"] = event.importance
          result["indicator"] = event.indicator
          result["period"] = event.period
          result["previous"] = event.previous
          result["previousRaw"] = event.previousRaw
          result["source"] = event.source
          result["source_url"] = event.source_url
          result["ticker"] = event.ticker
          result["title"] = event.title

          return result
        }
      )
    },
    sortedEvents() {
      return this.convertedEvents.slice().sort((a, b) => a.time - b.time)
    },
    groupedEventsByDate() {
      return this.sortedEvents.reduce((groups, event) => {
        const date = this.formatDate(event.date)
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(event)
        return groups
      }, {})
    }
  },
  methods: {
    getSignalIcon(relevance) {
      switch (relevance) {
        case -1:
          return 'mdi-signal-cellular-1'
        case 0:
          return 'mdi-signal-cellular-2'
        case 1:
          return 'mdi-signal-cellular-3'
        default:
          return ''
      }
    },
    formatDate(timestamp) {
      const date = new Date(timestamp)
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(date)
    },
    formatCustomDate(dateString) {
      let day, month, year
      if (this.$i18n.locale === 'pt') {
        const parts = dateString.split('/');
        day = parseInt(parts[0], 10);
        month = parseInt(parts[1], 10);
        year = parseInt(parts[2], 10);
      } else if (this.$i18n.locale === 'en') {
        const parts = dateString.split('/');
        month = parseInt(parts[0], 10);
        day = parseInt(parts[1], 10);
        year = parseInt(parts[2], 10);
      }
      const date = new Date(year, month - 1, day);
      const options = { weekday: 'long', day: 'numeric', month: 'long' };
      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(date);
    },
    formatTime(timestamp) {
      let date = new Date()
      if (timestamp) {
        date = new Date(timestamp)
      }
      const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true }
      return date.toLocaleTimeString(this.$i18n.locale, timeOptions)
    },
    openEvent(url) {
      window.open(url, "_blank");
    },
    toggleExpand(item) {
      if (this.expanded.includes(item)) {
        this.expanded = this.expanded.filter(i => i !== item)
      } else {
        this.expanded.push(item)
      }
    }
  }
}
</script>

<style scoped>
.table-title {
  background-color: #151515
}

.time-column {
  width: 10%
}

.icon-column {
  width: 4%
}

.headline-column {
  width: 82%
}
</style>
