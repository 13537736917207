<template>
  <v-app-bar
    app
    :height="105"
    color="black"
  >
    <div
      style="width: 100%;"
      class="d-flex flex-column fill-height"
    >
      <div class="d-flex text-caption text--disabled font-weight-regular">
        Joao Brasileiro | ACME LTDA
        <v-spacer></v-spacer>
        {{ $t('toolbar.timeZone') }}: UTC
      </div>

      <div class="my-auto d-flex">
        <v-img
          style="height: 26px;"
          aspect-ratio="16/9"
          :src="require(`./../assets/clearfx.ai.svg`)"
          max-width="148"
          contain
          class="my-auto"
        >
        </v-img>

        <v-divider vertical inset class="mx-4"></v-divider>

        <div 
          class="my-auto mr-3 pr-3 font-weight-light text--secondary"
          style="font-size: 26px !important;"
        >
          {{ getRole }}
        </div>

        <v-spacer></v-spacer>

        <div
          class="d-flex align-center"
        >
          <v-slide-group
            class="d-flex mb-n3"
            style="width: 550px;"
            show-arrows
          >
            <v-slide-item
              v-for="(sparkline, index) in sparklineList"
              :key="index"
            >
              <sparkline-chart 
                :sparklineData="sparkline"
                style="min-width: 150px;"
              >
              </sparkline-chart>
            </v-slide-item>
          </v-slide-group>

          <v-divider inset vertical></v-divider>

          <div>
            <economic-calendar></economic-calendar>
          </div>

          <v-divider inset vertical></v-divider>

          <div style="cursor: pointer;" v-ripple>
            <news-carousel></news-carousel>
          </div>

          <v-divider inset vertical></v-divider>

          <div>
            <avg-ptax></avg-ptax>
          </div>
        </div>

        <v-spacer></v-spacer>

        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-auto ml-12"
              v-bind="attrs"
              v-on="on"
              icon
            >
            <v-img
              v-if="isPtBRLocale"
              style="height: 32px;"
              content-class="rounded-lg"
              class="rounded-lg"
              aspect-ratio="16/9"
              max-width="32"
              width="32"
              :src="require(`./../assets/brazil_flag.png`)"
            />
            <v-img
              v-else
              style="height: 32px;"
              content-class="rounded-lg"
              class="rounded-lg"
              aspect-ratio="16/9"
              max-width="32"
              width="32"
              :src="require(`./../assets/usd_flag.png`)"
            />
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="changeLanguage('en')">
              <v-img
                style="height: 32px;"
                content-class="rounded-lg"
                class="rounded-lg mr-2"
                aspect-ratio="16/9"
                max-width="32"
                width="32"
                :src="require(`./../assets/usd_flag.png`)"
              />
              <v-list-item-title>
                EN-US
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="changeLanguage('pt')">
              <v-img
                style="height: 32px;"
                content-class="rounded-lg"
                class="rounded-lg mr-2"
                aspect-ratio="16/9"
                max-width="32"
                width="32"
                :src="require(`./../assets/brazil_flag.png`)"
              />
              <v-list-item-title>
                PT-BR
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="my-auto ml-5"
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>
                mdi-account
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="logoutClicked()">
              <v-list-item-title>
                {{ $t('instructions.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <template v-slot:extension>
      <v-tabs
        v-model="tab"
        dense
        background-color="#121212"
        :slider-size="5"
        :height="40"
        class="mt-3"
        style="border-bottom: 1px solid gray;"
      >
        <v-tab
          v-for="(menu, index) in menus"
          :key="index"
          :disabled="menu.disabled"
          :to="menu.to"
        >
          <span 
            class="white--text text-capitalize"
            style="font-weight: 400;"
          >
            {{ menu.label }}
          </span>
        </v-tab>
      </v-tabs>
    </template>

    <v-snackbar
      color="attention"
      v-model="logoutError"
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="attention"
          text
          v-bind="attrs"
          @click="logoutError = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-app-bar>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { useAuthStore, CLEARFXAI_API_URL, SANDBOX_PATH } from "./../store/index";
import EconomicCalendar from "./../components/EconomicCalendar.vue"
import NewsCarousel from "./../components/NewsCarousel.vue"
import AvgPtax from "./../components/AvgPtax.vue"
import SparklineChart from "./../components/SparklineChart.vue"

export default {
  components: {
    EconomicCalendar,
    NewsCarousel,
    AvgPtax,
    SparklineChart
  },
  data () {
    return {
      tab: 0,
      logoutError: false,
      errorMessage: undefined,
      // menus: [
      //   { label: this.$t('menus.fxmarket'), to: "/grid/fxmarket" },
      //   { label: this.$t('menus.trading'), to: "/grid/trading" },
      //   { label: this.$t('menus.reports'), to: "/grid/reports" },
      //   //{ label: "Monitoring (TBR)", disabled: true },
      //   //{ label: "IP Allowlist (TBR)", disabled: true },
      //   //{ label: "Session (TBR)", disabled: true },
      //   //{ label: "Notifications (TBR)", disabled: true }
      // ],
      sparklineList: [],
      eventSource: undefined,
      GRIDPretty: "Grid",
      GRIDPROPretty: "Grid PRO"
    }
  },
  mounted () {
    this.listenFeed()
  },
  methods: {
    ...mapActions(useAuthStore, [
      "logout",
      "removeSessionLocalStorage",
      "getUserRoleFromLocalStorage"
    ]),
    logoutClicked () {
      this.logout()
        .then(() => {
          this.authenticated = false
          this.userId = undefined
          this.removeSessionLocalStorage()
          this.$router.push("/")
        })
        .catch((error) => {
          this.logoutError = true
          this.errorMessage = "Unable to log out. Try again later."
          console.log(error)
        })
    },
    listenFeed () {
      const sparklinesEvent = "sparklines"
      // const pingEvent = "ping"
      // const errorEvent = "error"
      // const openedConnectionEvent = "open"
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/feed`
      this.eventSource = new EventSource(`${url}`)
      this.eventSource.addEventListener(sparklinesEvent, event => {
        const data = JSON.parse(event.data)
        this.sparklineList = data
      })
      // IT EVENTS
      // this.eventSource.addEventListener(pingEvent, () => { })
      // this.eventSource.addEventListener(errorEvent, () => { })
      // this.eventSource.addEventListener(openedConnectionEvent, () => { })
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('userLanguage', lang)
      window.location.reload(true)    },
  },
  computed: {
    ...mapWritableState(useAuthStore, [
      "authenticated",
      "userId"
    ]),
    isPtBRLocale() {
      return this.$i18n.locale === "pt"
    },
    menus() {
      return [
        { label: this.$t("menus.fxmarket"), to: "/grid/fxmarket" },
        { label: this.$t("menus.trading"), to: "/grid/trading" },
        { label: this.$t("menus.reports"), to: "/grid/reports" },
      ]
    },
    getRole () {
      const titles = {
        grid: this.GRIDPretty,
        gridpro: this.GRIDPROPretty
      }
      const title = titles[this.getUserRoleFromLocalStorage()]
      return title
    },
  }
}
</script>

<style scoped>
::v-deep .v-toolbar__extension {
  height: 42px !important;
}
</style>
