<template>
  <div>
    <v-row no-gutters class="mb-8 px-8">
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>

      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="10"
        xl="10"
        class="pt-8"
      >
        <div class="d-flex flex-column">
          <div class="d-flex mb-10 pb-8">
            <div 
              class="text-h3 font-weight-light" 
              style="
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
              "
            >
              {{ $t('title.trading') }}
            </div>
            <v-spacer></v-spacer>
            <div class="font-weight-light">
              <!-- {{ now }} -->
            </div>
          </div>

          <v-row no-gutters>
            <v-col cols="1">
            </v-col>

            <v-col cols="10">
              <v-row no-gutters class="justify-center">
                <v-col cols="7" class="d-flex justify-center">
                  <div class="mr-8">
                    <span>{{ $t('quoteInfo.side') }}</span>
                    <v-radio-group
                      v-model="side"
                      class="mt-0"
                      row
                      mandatory
                      :disabled="loadingNewRfq || gridActive"
                    >
                      <v-radio
                        :label="$t('side.buy')"
                        value="BUY"
                      >
                      </v-radio>
                      <v-radio
                        :label="$t('side.sell')"
                        value="SELL"
                      >
                      </v-radio>
                    </v-radio-group>
                  </div>

                  <div class="mr-8">
                    <span>{{ $t('quoteInfo.ccy') }}</span>
                    <v-radio-group
                      v-model="ccy"
                      class="mt-0"
                      mandatory
                      row
                      :disabled="loadingNewRfq || gridActive"
                    >
                      <v-radio
                        label="USD"
                        value="USD"
                      >
                      </v-radio>
                      <v-radio
                        disabled
                        label="EUR"
                        value="EUR"
                        class="disabled"
                      >
                      </v-radio>
                    </v-radio-group>
                  </div>

                  <div>
                    <span>{{ $t('quoteInfo.settlement') }}</span>
                    <v-radio-group
                      v-model="settlement"
                      class="mt-0"
                      mandatory
                      row
                      :disabled="loadingNewRfq || gridActive"
                    >
                      <v-radio
                        disabled
                        label="D+0"
                        value="d+0"
                        class="disabled"
                      >
                      </v-radio>
                      <v-radio
                        disabled
                        label="D+1"
                        value="d+1"
                        class="disabled"
                      >
                      </v-radio>
                      <v-radio
                        label="D+2"
                        value="d+2"
                      >
                      </v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>

              <v-row class="justify-center">
                <v-col
                  cols="4"
                  class="d-flex align-baseline justify-center"
                >
                  <v-text-field
                    v-model="amount"
                    v-money="money"
                    :label="$t('quoteInfo.amountCcy')"
                    :disabled="loadingNewRfq || gridActive"
                    class="mr-2"
                    style="max-width: 300px;"
                  >
                  </v-text-field>

                  <v-btn
                    elevation="0"
                    tile
                    color="primary"
                    @click="makeQuote()"
                    :loading="loadingNewRfq"
                    :disabled="disableQuoteButton"
                  >
                    {{ $t('quote.quote') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider class="mb-10 mt-8"></v-divider>
              
              <v-row class="mb-8 pa-4">
                <v-col cols="3"> </v-col>

                <v-col cols="4" class="d-flex flex-wrap align-center">
                  <span class="mr-2">
                    {{ $t('quoteInfo.bestRate') }}
                  </span>
                  <!-- <v-chip class="mr-2" label color="gridAliveLight"> 5.0687 </v-chip> -->
                  <span 
                    class="mr-2 gridAliveLight--text font-weight-bold font-size-rate line-height"
                    style="min-width: 74px;"
                  >
                    {{ bestRate ? bestRate.quote_bank_rate : "-" }}
                  </span>

                  <v-btn
                    elevation="0"
                    tile
                    outlined
                    color="primary"
                    class="mr-2"
                    small
                    @click="openConfirmation(bestRate)"
                    :disabled="disableGrid"
                  >
                    <span 
                      class="white--text"
                      :class="{'text--disabled': disableGrid }"
                    >
                      {{ $t('instructions.accept') }}
                    </span>
                  </v-btn>

                  <v-btn
                    elevation="0"
                    tile
                    outlined
                    small
                    @click="declineQuote()"
                    color="grey darken-2"
                    :disabled="disableGrid"
                  >
                    <span 
                      class="white--text"
                      :class="{'text--disabled': disableGrid }"
                    >
                      {{ $t('instructions.decline') }}
                    </span>
                  </v-btn>
                </v-col>

                <v-col cols="3" class="d-flex flex-wrap align-center">
                  <span class="mr-2">{{ $t('quoteInfo.cfxIndex') }}</span>
                  <span class="mr-2 gridAliveLight--text font-weight-bold font-size-rate line-height">
                    -
                  </span>
                </v-col>
              </v-row>

              <v-row
                no-gutters
              >
                <!-- <v-col cols="1"> </v-col> -->
                <v-col cols="3">
                  <div
                    class="pa-2 text-center fill-height text-caption text--secondary"
                  >
                    {{ $t('quoteInfo.bank') }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    class="pa-2 text-center fill-height text-caption text--secondary"
                  >
                    {{ $t('quoteInfo.rate') }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    class="pa-2 text-center fill-height text-caption text--secondary"
                  >
                    {{ $t('quoteInfo.spreadAvg') }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    class="pa-2 text-center fill-height text-caption text--secondary"
                  >
                    {{ $t('quoteInfo.spreadPtax') }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    class="pa-2 text-center fill-height text-caption text--secondary"
                  >
                    {{ $t('quoteInfo.total') }}
                  </div>
                </v-col>
                <v-col cols="1">
                  <div
                    class="pa-1 text-center fill-height"
                  >
                  </div>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                v-for="(quoteItem, index) of quoteList"
                :key="index"
                class="align-center mb-1-4"
              >
                <!-- <v-col cols="1"> </v-col> -->
                <v-col cols="3">
                  <div
                    class="pa-2 text-center text-truncate border-right-grid"
                    :class="{ 'border-bottom-grid': quoteList.length === 1 || index !== quoteList.length -1 }"
                  >
                    {{ quote ? quote.meta[quoteItem.quote_bank_id]["bank_label"] : "-" }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    style="padding: 3px;"
                    class="font-size-rate text-center fill-height border-right-grid"
                    :class="{
                      'border-bottom-grid': quoteList.length === 1 || index !== quoteList.length -1,
                      'gridAliveBlur': gridActive && quoteItem.quote_id !== bestRate.quote_id,
                      'gridAliveLight': gridActive && (quote && quote.quote_resp_id) && quoteItem.quote_id === bestRate.quote_id,
                      'gridBlueBlur': disableGrid
                    }"
                  >
                    <!-- 'gridBlueLight': index === 2 -->
                    {{ quoteItem.quote_bank_rate }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    class="pa-2 gridBlueBlur text-center fill-height border-right-grid"
                    :class="{ 'border-bottom-grid': quoteList.length === 1 || index !== quoteList.length -1 }"
                  >
                    -
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    :class="{ 'border-bottom-grid': quoteList.length === 1 || index !== quoteList.length -1 }"
                    class="pa-2 gridBlueBlur text-center fill-height border-right-grid"
                  >
                    -
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    :class="{ 'border-bottom-grid': quoteList.length === 1 || index !== quoteList.length -1 }"
                    class="pa-2 gridBlueBlur text-center fill-height"
                  >
                    -
                  </div>
                </v-col>
                <v-col cols="1">
                  <div
                    class="pa-0-5 text-center fill-height"
                    :class="{ 'border-bottom-grid': quoteList.length === 1 || index !== quoteList.length -1 }"
                  >
                    <v-btn
                      elevation="0"
                      tile
                      outlined
                      color="primary"
                      small
                      height="34"
                      width="100%"
                      @click="openConfirmation(quoteItem)"
                      :disabled="disableGrid"
                    >
                      <span 
                        class="white--text"
                        :class="{ 'text--disabled': disableGrid }"
                      >
                        {{ $t('instructions.accept') }}
                      </span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="1">
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
    </v-row>
    
    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet
          width="100%"
          class="py-12 px-8"
        >
          <v-row no-gutters>
            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>

            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              class="pt-4 d-flex flex-column"
            >
              <div class="text-h5 font-weight-light mb-10">
                RFQs
              </div>

              <v-card outlined>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="elements"
                  class="elevation-1"
                >
                  <template #[`item.rfq_side`]="{ item }">
                    <span :class="{ 'alive--text': item.rfq_side === 'BUY', 'orangeColor--text': item.rfq_side === 'SELL' }">
                      {{ $t('quoteInfo.' + item.rfq_side.toLowerCase()) }}
                    </span>
                  </template>
                  <template #[`item.rfq_amount`]="{ item }">
                    <span>
                      {{ item.rfq_amount | formatPrice }}
                    </span>
                  </template>
                  <!-- 
                  <template #[`item.rfq_deal`]="{ item }">
                    <span :class="{ 'alive--text': item.rfq_deal === 1 }" class="font-weight-bold">
                      {{ item.rfq_deal === 1 ? 'Deal' : '-' }}
                    </span>
                  </template> 
                  -->
                  <template #[`item.rfq_deal_time`]="{ item }">
                    <div class="d-flex flex-column">
                      <span>{{ item.rfq_deal_time | formatDate }}</span>
                    </div>
                  </template>
                  <template #[`item.rfq_spotsett`]="{ item }">
                    <span>
                      {{ item.rfq_spotsett.toUpperCase() }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
              <!-- <v-card outlined>
              </v-card> -->
            </v-col>

            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      color="green accent-4"
      :timeout="4000"
      bottom
    >
      <span class="black--text">
        Successful deal
      </span>
    </v-snackbar>

    <v-dialog
      v-model="dialogConfirm"
      persistent
      width="500"
      overlay-opacity="0.59"
    >
      <v-card
        min-height="600"
        class="d-flex flex-column"
      >
        <div
          v-if="dialogConfirm && !loadingOrder && !successOrder"
          class="mb-4"
        >
          <base-timer 
            :timeLimit="selectedQuote.quote_bank_timedue"
            @timeDue="quoteTimedue=true"
          >
          </base-timer>
        </div>

        <v-card-title class="justify-center">
          {{ loadingOrder ? $t('quoteInfo.loadingOrder').toUpperCase() : (successOrder ? $t('quoteInfo.succesDeal').toUpperCase() : $t('quoteInfo.confirmation').toUpperCase()) }}
        </v-card-title>

        <v-card-text 
          v-if="!loadingOrder && !successOrder"
          class="d-flex flex-column justify-center" 
        >
          <p>{{ $t('quoteInfo.side') }}: {{ side }}</p>
          <p>{{ $t('quoteInfo.ccy') }}: {{ ccy }}</p>
          <p>{{ $t('quoteInfo.settlement') }}: {{ settlement }}</p>
          <p>{{ $t('quoteInfo.amountCcy') }}: {{ amount }}</p>
          <v-divider class="my-4"></v-divider>
          <p>{{ $t('quoteInfo.lastUpdate') }}: {{ selectedQuote ? selectedQuote.quote_bank_time : "-" | formatDate }}</p>
          <p>{{ $t('quoteInfo.timeDue') }}: {{ selectedQuote ? selectedQuote.quote_bank_timedue : "-" | formatDate }}</p>
          <p>{{ $t('quoteInfo.bank') }}: {{ selectedQuote ? quote.meta[selectedQuote.quote_bank_id]["bank_label"] : "-" }}</p>
          <p>{{ $t('quoteInfo.rate') }} {{ selectedQuote ? selectedQuote.quote_bank_rate : "-" }}</p>
          <p>{{ $t('quoteInfo.spreadAvg') }}: {{ "-" }}</p>
          <p>{{ $t('quoteInfo.spreadPtax') }}: {{ "-" }}</p>
          <p>{{ $t('quoteInfo.total') }}: {{ "-" }}</p>
        </v-card-text>

        <v-card-text
          v-if="loadingOrder"
          class="d-flex flex-column align-center fill-height" 
          style="justify-content: space-evenly; padding-top: 40%;"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="140"
          >
          </v-progress-circular>
        </v-card-text>

        <v-card-text 
          v-if="successOrder"
          class="d-flex flex-column align-center fill-height" 
          style="justify-content: space-evenly; padding-top: 18%;"
        >
          <div class="py-10">
            <v-icon style="font-size: 180px;">
              mdi-check-circle
            </v-icon>
          </div>

          <div class="py-16">
            <v-btn 
              color="primary" 
              @click="closeDialog()"
              elevation="0"
              tile
            >
              {{ $t('instructions.close') }}
            </v-btn>
          </div>
        </v-card-text>

        <v-card-actions
          v-if="!successOrder && !loadingOrder" 
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="cancelConfirmation()"
          >
            {{ $t('instructions.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            tile
            @click="makeOrder()"
            :loading="loadingOrder"
            :disabled="quoteTimedue"
          >
            {{ $t('instructions.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="buyDialog"
      persistent
      width="500"
      overlay-opacity="0.59"
    >
      <v-card
        min-height="600"
        class="d-flex flex-column justify-space-between"
        elevation="10"
      >
        <v-img
          src="./../assets/clearfx.ai.svg"
          aspect-ratio="16/9"
          max-height="50"
          class="mt-8"
          contain
        ></v-img>

        <v-card-title class="text-h6 justify-center font-weight-bold">
          {{ $t('buyDialog.title') }}
        </v-card-title>

        <v-card-text class="text-subtitle-1 text-center px-6">
          {{ $t('buyDialog.instruction.instructionOne') }}
          <br><br>
          {{ $t('buyDialog.instruction.instructionTwo') }}
          <br><br>
          {{ $t('buyDialog.instruction.instructionThree') }}
        </v-card-text>

        <v-card-actions class="d-flex pb-6">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="buyDialog = false"
          >
            {{ $t('buyDialog.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="redirectToProPurchase"
          >
            {{ $t('buyDialog.getGridpro') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useDisplayStore, useAlgoRFQStore, useAuthStore } from "./../store/index"
import { mapState, mapActions } from "pinia"
import * as utils from "./../utils.js"
import { VMoney } from "v-money"
import BaseTimer from "./../components/BaseTimer.vue"

export default {
  directives: {
    money: VMoney
  },
  components: {
    BaseTimer
  },
  filters: {
    formatDate (timestamp) {
      return utils.getLocaleFormattedDateTime(timestamp)
    },
    formatPrice (price) {
      return utils.formatPrice(price)
    },
  },
  data () {
    return {
      side: undefined,
      ccy: undefined,
      settlement: undefined,
      amount: 0,
      snackbar: false,
      interval: null,
      quote: undefined,
      rfqList: [],
      quoteTemplate: {
        quote_list: [
          {
            quote_id: "-",
            quote_bank_id: "-",
            quote_bank_rate: "-",
            quote_bank_time: -1,
            quote_bank_timedue: -1
          }
        ],
        meta: {
          "-": {
            bank_id: "-",
            bank_label: "-",
            bank_enable: 1,
            bank_tag: "-"
          }
        }
      },
      /*
      {
        "quote_resp_id": "1e5f2f0b-b921-4b52-8cec-33257c056df2",
        "quote_list": [
          {
            "quote_id": "1a3e34d9-fca6-4ced-9ca8-348f3cc683cd",
            "quote_bank_id": "a2249a6f",
            "quote_bank_rate": 5.0687,
            "quote_bank_time": 1712282386.3042212,
            "quote_bank_timedue": 1712282476.3042216
          },
          {
            "quote_id": "1627774d-5150-4f61-a7dc-eb912ec23a69",
            "quote_bank_id": "7c4a0ca4",
            "quote_bank_rate": 5.0641,
            "quote_bank_time": 1712282386.3042338,
            "quote_bank_timedue": 1712282476.3042338
          },
          {
            "quote_id": "ca56c08d-1825-4e13-b818-cc17a29d27ba",
            "quote_bank_id": "b1348c9a",
            "quote_bank_rate": 5.0682,
            "quote_bank_time": 1712282386.3042426,
            "quote_bank_timedue": 1712282476.3042428
          }
        ],
        "meta": {
    "a2249a6f": {
        "bank_id": "a2249a6f",
        "bank_label": "BANCO DO BRASIL S.A.",
        "bank_enable": 1,
        "bank_tag": ""
    },
    "7c4a0ca4": {
        "bank_id": "7c4a0ca4",
        "bank_label": "BANCO SANTANDER (BRASIL) S.A.",
        "bank_enable": 0,
        "bank_tag": ""
    },
    "b1348c9a": {
        "bank_id": "b1348c9a",
        "bank_label": "ITAU UNIBANCO S.A.",
        "bank_enable": 0,
        "bank_tag": ""
    },
    "729f8918": {
        "bank_id": "729f8918",
        "bank_label": "BANCO DAYCOVAL S.A.",
        "bank_enable": 0,
        "bank_tag": ""
    }
        },
        "algo": {
          "algo_id": 0,
          "algo_label": "ALGO_GRID"
        },
        "quote_rfqid": "a61a6547-cf85-411e-942f-65f8e0829bc1"
      },
      */
      // headers: [
      //   { text: this.$t('quoteInfo.dealTime'), value: 'rfq_deal_time', sortable: false },
      //   // { text: 'Status', value: 'rfq_deal', sortable: false },
      //   { text: this.$t('quoteInfo.ccy'), value: 'rfq_ccy', sortable: false },
      //   { text: this.$t('quoteInfo.side'), value: 'rfq_side', sortable: false },
      //   { text: this.$t('quoteInfo.amount'), value: 'rfq_amount', sortable: false },
      //   { text: this.$t('quoteInfo.settlement'), value: 'rfq_spotsett', sortable: false },
      //   { text: this.$t('quoteInfo.bankName'), value: 'bank_label', sortable: false },
      //   { text: this.$t('quoteInfo.id'), value: 'rfq_id', sortable: false }
      // ],
      loadingNewRfq: false,
      gridActive: false,
      rfqId: undefined,
      loadingOrder: false,
      retryCount: 0,
      dialogConfirm: false,
      selectedQuote: undefined,
      successOrder: false,
      quoteTimedue: false,
      GRIDPretty: "Grid",
      GRIDPROPretty: "Grid PRO",
      buyDialog: false,
    }
  },
  computed: {
    ...mapState(useDisplayStore, [
      "isXSmall",
      "isSmall",
      "isMedium",
      "isLarge",
      "isXLarge"
    ]),
    now () {
      return utils.getLocaleFormattedDateTime()
    },
    quoteList () {
      if (!this.quote) {
        return [
          {
            quote_id: "-",
            quote_bank_id: "-",
            quote_bank_rate: 0,
            quote_bank_time: -1,
            quote_bank_timedue: -1
          }
        ]
      }
      return this.quote.quote_list
    },
    bestRate () {
      const bestRate = this.quoteList.reduce(
        (prev, current) => (prev && prev.quote_bank_rate < current.quote_bank_rate) ? prev : current,
      undefined)
      return bestRate
    },
    disableGrid () {
      return !this.gridActive && (this.quote && !this.quote.quote_resp_id)
    },
    elements () {
      if (!this.rfqList.elements) {
        return []
      }
      return this.rfqList.elements.map(
        (element) => {
          let result = {}
          result["rfq_id"] = element.rfq_id
          result["rfq_ccy"] = element.rfq_ccy
          result["rfq_side"] = element.rfq_side
          result["rfq_amount"] = element.rfq_amount
          result["rfq_spotsett"] = element.rfq_spotsett
          result["rfq_deal_time"] = element.rfq_deal_time
          // result["rfq_deal"] = element.rfq_deal
          let rfqDealQuoteid = element.rfq_deal_quoteid
          let quote = element.rfq_lastquote.quote_list.filter(
            (quote) => {
              return quote.quote_id === rfqDealQuoteid
            }
          )[0]
          if (quote) {
            let quoteBankId = quote.quote_bank_id
            let quoteBankName = element.rfq_lastquote.meta[quoteBankId].bank_label
            result["bank_label"] = quoteBankName
          }
          return result
        }
      )
    },
    money() {
      let prefix = ""
      if (this.ccy === "USD") {
        prefix = "$ "
      } else if (this.ccy === "EUR") {
        prefix = "€ "
      }
      return {
        thousands: ",",
        prefix: prefix
      }
    },
    disableQuoteButton() {
      return this.gridActive || this.convertCurrencyToNumber(this.amount) === 0.00;
    },
    headers() {
      return [
        { text: this.$t('quoteInfo.dealTime'), value: 'rfq_deal_time', sortable: false },
        // { text: 'Status', value: 'rfq_deal', sortable: false },
        { text: this.$t('quoteInfo.ccy'), value: 'rfq_ccy', sortable: false },
        { text: this.$t('quoteInfo.side'), value: 'rfq_side', sortable: false },
        { text: this.$t('quoteInfo.amount'), value: 'rfq_amount', sortable: false },
        { text: this.$t('quoteInfo.settlement'), value: 'rfq_spotsett', sortable: false },
        { text: this.$t('quoteInfo.bankName'), value: 'bank_label', sortable: false },
        { text: this.$t('quoteInfo.id'), value: 'rfq_id', sortable: false }
      ]
    },
    getRole () {
      const titles = {
        grid: this.GRIDPretty,
        gridpro: this.GRIDPROPretty
      }
      const title = titles[this.getUserRoleFromLocalStorage()]
      return title
    },
  },
  mounted () {
    this.getAPIKey()
    this.resetGrid()
    this.fetchOrderList()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions(useAlgoRFQStore, [
      "newRfq",
      "getQuote",
      "order",
      "orderList",
      "declineOrder"
    ]),
    ...mapActions(useAuthStore, [
      "getAPIKey",
      "getUserRoleFromLocalStorage"
    ]),
    makeQuote () {
      this.loadingNewRfq = true
      let amount = JSON.parse(JSON.stringify(this.amount))
      amount = this.convertCurrencyToNumber(amount)
      const payload = {
        "rfq_algoid": 0,
        "rfq_amount": amount,
        "rfq_ccy": this.ccy,
        "rfq_side": this.side,
        "rfq_spotsett": this.settlement
      }
      this.newRfq(payload)
        .then((response) => {
          const data = response.data
          this.rfqId = data.rfq_id
          this.initQuoteLoop()
          this.fetchOrderList();
        })
        .catch ((error) => {
          const status = error.response.status
          if (status === 401 && this.retryCount === 0) {
            this.retryCount += 1
            this.getAPIKey()
            setTimeout(() => {
              this.makeQuote()
            }, 2000)
          }
        })
        .finally(() => {
          this.loadingNewRfq = false
        })
    },
    fetchQuote () {
      this.getQuote(this.rfqId)
        .then((response) => {
          const data = response.data
          this.quote = data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {})
    },
    fetchOrderList () {
      this.orderList(100)
        .then((response) => {
          this.rfqList = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {})
    },
    makeOrder () {
      if (this.getRole === 'Grid') {
        this.cancelConfirmation()
        this.buyDialog = true
        return
      } 
      this.loadingOrder = true
      const payload = {
        deal_quoteid: this.selectedQuote.quote_id,
        deal_rfqid: this.rfqId
      }
      this.order(payload)
        .then(() => {
          this.successOrder = true
          clearInterval(this.interval)
          // this.snackbar = true
          this.fetchOrderList();
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingOrder = false
        })
    },
    declineQuote() {
      const rfqid = this.rfqId
      this.declineOrder(rfqid)
        .then(() => {
          this.resetGrid()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    resetGrid () {
      this.amount = "0"
      this.gridActive = false
      this.quote = JSON.parse(JSON.stringify(this.quoteTemplate))
      this.successOrder = false
      this.selectedQuote = undefined
      clearInterval(this.interval)
    },
    openConfirmation (quote) {
      this.dialogConfirm = true
      this.selectedQuote = JSON.parse(JSON.stringify(quote))
      clearInterval(this.interval)
    },
    convertCurrencyToNumber(currencyString) {
      if (typeof currencyString === 'string') {
        return parseFloat(currencyString.replace(/[^0-9.-]+/g,""));
      } else {
        return 0.00
      }
    },
    cancelConfirmation () {
      this.dialogConfirm = false
      this.quoteTimedue = false
      this.initQuoteLoop()
    },
    closeDialog () {
      this.dialogConfirm = false
      this.quoteTimedue = false
      this.resetGrid()
      clearInterval(this.interval)
    },
    initQuoteLoop () {
      this.fetchQuote()
      this.gridActive = true
      this.interval = setInterval(() => {
        this.fetchQuote()
      }, 4000);
    },
    redirectToProPurchase () {
      const url = '#'
      window.open(url, "_blank")
    }
  }
}
</script>

<style scoped>
.border-right-grid {
  border-right: 1px solid #ffffff1f; /* #212121; */
}
.border-bottom-grid {
  border-bottom: 1px solid #ffffff1f; /* #212121; */
}
::v-deep div.v-radio.disabled.v-radio--is-disabled.theme--dark > div > i {
  color: #303030;
}
.font-size-rate {
  font-size: 22px;
}
.line-height {
  line-height: 26px;
}
.relative-container {
    position: relative;
}
.center-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
