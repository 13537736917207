import { defineStore } from "pinia"
import axiosInstance from "@/http.js"
const axios = axiosInstance


export const CLEARFXAI_API_URL = process.env.VUE_APP_CLEARFXAI_API_URL
export const APIKEY = process.env.VUE_APP_APIKEY
export const APIVERSION = process.env.VUE_APP_API_VERSION
export const APP_PROD = process.env.VUE_APP_PROD

export const API_PATH = `v${APIVERSION}/clearfxai`

export let AUTH_PATH = `apiauth/${API_PATH}`
export let SANDBOX_PATH = `apisandbox/v${APIVERSION}`
export let ALGO_RFQ_PATH = `${SANDBOX_PATH}/algorfq`
export let CONNECTIVITY_PATH = `apiconn`

// if (process.env.NODE_ENV === "development") {
// }

export const useUtilsStore = defineStore("utils", {
  state: () => ({
    orangeColor: "#FF6D00", // "#FFD600", // "#FB8C00",
    aliveColor: "#00c853",
    yellowTransp: "#4b2000", // "#816c00" // "#633b01"
    aliveTransp: "#003817"
  }),
  actions: {},
});

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authenticated: undefined,
    userId: undefined,
    apiKey: undefined,
    userRole: undefined
  }),
  getters: {
    isAuthenticated: (state) => state.authenticated,
    getUserId: (state) => state.userId,
    getApiKey: (state) => state.apiKey,
  },
  actions: {
    login (payload) {
      const url = `${CLEARFXAI_API_URL}/${AUTH_PATH}/login`
      return axios.post(url, payload)
        .then(response => {
          const data = response.data
          this.userRole = data.user_role
          this.updateTitle()
          return response
        })
    },
    async getAPIKey () {
      try {
        const url = `${CLEARFXAI_API_URL}/${CONNECTIVITY_PATH}/apikey`
        const response = await axios.get(url)
        const data = response.data
        this.apiKey = data.apikey
      } catch (error) {
        console.log(error)
      }
    },
    getAuthenticated () {
      this.loadAuthenticatedAndUserIdStateFromLocalStorage()
      const userId = this.getUserId
      const url = `${CLEARFXAI_API_URL}/${AUTH_PATH}/${userId}`
      return axios.get(url)
    },
    logout () {
      const userId = this.getUserId
      const url = `${CLEARFXAI_API_URL}/${AUTH_PATH}/logout/${userId}`
      return axios.delete(url)
        .then(() => {
          this.removeSessionLocalStorage()
          this.userRole = undefined
          this.updateTitle()
        });
    },
    loadAuthenticatedAndUserIdStateFromLocalStorage () {
      let userId = localStorage.getItem("userId")
      let userRole = localStorage.getItem("userRole")
      let authenticated = false
      if (userId) {
        authenticated = true
      }
      this.authenticated = authenticated
      this.userId = userId
      this.userRole = userRole
      this.updateTitle()
    },
    updateTitle () {
      const userRole = this.userRole || "Grid"
      if (userRole === "grid") {
        document.title = "clearfx.ai | Grid";
      } else if (userRole === "gridpro") {
        document.title = "clearfx.ai | Grid PRO";
      } else {
        document.title = "clearfx.ai | Grid";
      }
    },
    saveSessionLocalStorage (data) {
      localStorage.setItem("userId", data.user_id)
      localStorage.setItem("userRole", data.user_role)
      this.userRole = data.user_role
    },
    removeSessionLocalStorage () {
      localStorage.removeItem("userId")
      localStorage.removeItem("userRole")
      localStorage.removeItem("apiKey")
    },
    getSessionFromLocalStorage () {
      const data = localStorage.getItem("userId")
      return data
    },
    getUserRoleFromLocalStorage () {
      const data = localStorage.getItem("userRole")
      return data
    },
  },
});

export const useDisplayStore = defineStore("display", {
  state: () => ({
    breakpoint: undefined,
    windowHeight: window.innerHeight,
    headerHeight: 153,
    // footerHeight: 30,
    marginLayout: 32,
  }),
  getters: {
    isMobile() {
      return this.mobile
    },
    currentBreakpoint () {
      return this.breakpoint
    },
    isXLarge () {
      return this.currentBreakpoint === "xl"
    },
    isLarge () {
      return this.currentBreakpoint === "lg"
    },
    isMedium () {
      return this.currentBreakpoint === "md"
    },
    isSmall () {
      return this.currentBreakpoint === "sm"
    },
    isXSmall () {
      return this.currentBreakpoint === "xs"
    },
    contentHeight () {
      const contentHeight = 
        this.windowHeight 
        - this.headerHeight 
        // - this.footerHeight 
        // - this.marginLayout // x axis
        // - this.marginLayout // y axis
      return `${contentHeight}`
    },
  },
  actions: {
  }
})

export const useAlgoRFQStore = defineStore("algoRFQStore", {
  state: () => ({}),
  actions: {
    newRfq (data) {
      const authStore = useAuthStore()
      const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${ALGO_RFQ_PATH}/new`
      return axios.post(url, data, {
        headers: {
          APIKEY: apikey
        }
      })
    },
    getQuote (rfqId) {
      const authStore = useAuthStore()
      const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${ALGO_RFQ_PATH}/quote/${rfqId}`
      return axios.get(url, {
        headers: {
          APIKEY: apikey
        }
      })
    },
    order (payload) {
      const authStore = useAuthStore()
      const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${ALGO_RFQ_PATH}/order`
      return axios.post(url, payload, {
        headers: {
          APIKEY: apikey
        }
      })
    },
    async orderList (count) {
      const authStore = useAuthStore()
      await authStore.getAPIKey()
      const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/apisandbox/v1/rfqdb/order/list?count=${count}`
      return axios.get(url, {
        headers: {
          APIKEY: apikey
        }
      })
    },
    getNews () {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/news`
      return axios.get(url
        // , {
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      )
    },
    // getEconomicCalendar () {
    //   // const authStore = useAuthStore()
    //   // const apikey = authStore.getApiKey
    //   const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/economic-calendar`
    //   return axios.get(url
    //     // , {
    //     //   headers: {
    //     //     APIKEY: apikey
    //     //   }
    //     // }
    //   )
    // },
    getAvgPtax () {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/avg-ptax`
      return axios.get(url
        // , {
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      )
    },
    getFxMarketChartIntraday () {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/fx-market-chart-intraday`
      return axios.get(url
        // , {
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      )
    },
    getFxMarketChartDaily () {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/fx-market-chart-daily`
      return axios.get(url
        // , {
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      )
    },
    getFxMarketNews (topics, sort, limit) {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/fx-market-news`
      return axios.get(url, {
        params: {
          topics,
          sort: sort,
          limit: limit
        }
        // , {
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      })
    },
    getFxMarketEconomicCalendar(from_date, to_date, countries) {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/fx-market-economic-calendar`
      return axios.get(url, {
        params: {
          from_date: from_date,
          to_date: to_date,
          countries: countries
        }
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      });
    },
    getFxMarketCrossQuote (currencyPairs) {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const params = currencyPairs.map(pair => `${pair.fromCcy}-${pair.toCcy}`).join(',');
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/fx-market-cross-quote`
      return axios.get(url,{
        params: {
          pairs: params
        }
      }
        // , {
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      )
    },
    getDollarRadar () {
      // const authStore = useAuthStore()
      // const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${SANDBOX_PATH}/dollar-radar`
      return axios.get(url
        // , {
        //   headers: {
        //     APIKEY: apikey
        //   }
        // }
      )
    },
    declineOrder (rfqid) {
      const authStore = useAuthStore()
      const apikey = authStore.getApiKey
      const url = `${CLEARFXAI_API_URL}/${ALGO_RFQ_PATH}/decline?rfqid=${rfqid}`
      return axios.delete(url, {
        headers: {
          APIKEY: apikey
        }
      })
    }
  }
});